<template>
  <div class="bank-from">
    <div class="form-wrapper">
      <h3>银行信息（绑定收款账户）对公</h3>
      <div class="form-main">
        <el-form ref="enterpriseForm" :rules="rules" :model="enterpriseInfo">
          <el-form-item label="开户行" prop="bank">
            <el-input
              v-model="enterpriseInfo.bank"
              maxlength="30"
              placeholder="请输入开户行"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="银行卡号" prop="account">
            <el-input
              v-model="enterpriseInfo.account"
              maxlength="19"
              placeholder="请输入银行卡号"
              @input="enterpriseInfo.account = enterpriseInfo.account.replace(/[^\d]/g, '')"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="开户人姓名" prop="accountOwner">
            <el-input
              v-model="enterpriseInfo.accountOwner"
              maxlength="30"
              placeholder="请输入开户人姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="预留手机号码" prop="accountMobile">
            <el-input
              v-model.trim="enterpriseInfo.accountMobile"
              maxlength="11"
              @input="enterpriseInfo.accountMobile = enterpriseInfo.accountMobile.replace(/[^\d]/g, '')"
              placeholder="请输入银行预留手机号码"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <ul class="footer-btns">
      <li @click="preStep">上一步</li>
      <li @click="nextStep" class="active">下一步</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      enterpriseInfo: {
        licImg: '', // 营业执照图片
        name: '', // 公司名称（营业执照）
        tax: '', // 公司税号
        addr: '', // 公司地址
        bank: '', // 开户行
        account: '', // 银行账号
        accountOwner: '', // 开户人姓名
        accountMobile: '' // 银行预留手机号
      },
      // 校验规则
      rules: {
        bank: [
          { required: true, message: "请输入开户行", trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' },
          { min: 16, max: 19, message: '长度在 16 到 19 位', trigger: 'blur' }
        ],
        accountOwner: [
          { required: true, message: '请输入开户人姓名', trigger: 'blur' }
        ],
        accountMobile: [
          { validator: this.$rules.checkPhone, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init () {
      let enterpriseInfo = JSON.parse(localStorage.getItem('enterprise_info'))
      if (enterpriseInfo) {
        this.enterpriseInfo = enterpriseInfo
      }
    },

    // 点击上一步
    preStep() {
      localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
      this.$router.push({
        name: 'apply',
        query: { step: 3 }
      })
    },

    // 点击下一步
    nextStep() {
      this.$refs.enterpriseForm.validate(valid => {
        if (valid) {
          localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
          this.$router.push({
            name: 'apply',
            query: { step: 5 }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "form";
</style>

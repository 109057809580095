<template>
  <div class="st-main st-base-bg">
    <div class="apply-wrapper">
      <div class="st-title">
        <b>食神通商户入驻</b>
      </div>
      <ul class="tab-items">
        <li
          v-for="item in tabs"
          :key="item.index"
          :class="item.index <= step ? 'active' : ''">
          <div class="icon"></div>
          <div class="label">
            <b>{{ item.index }}</b>
            <span>{{ item.name }}</span>
            <i></i>
          </div>
        </li>
      </ul>
      <div class="main">
        <agreement v-if="step === 1" />
        <contacts v-if="step === 2" />
        <company v-if="step === 3" />
        <bank v-if="step === 4" />
        <shop v-if="step === 5" />
      </div>
    </div>
  </div>
</template>

<script>
import Agreement from '../components/agreement/Index'
import Contacts from '../components/Contacts'
import Company from '../components/Company'
import Bank from '../components/Bank'
import Shop from '../components/Shop'

export default {
  name: '',
  components: {
    Agreement,
    Contacts,
    Company,
    Bank,
    Shop
  },
  data () {
    return {
      step: 1,
      tabs: [
        {
          index: 1,
          name: '注册协议'
        },
        {
          index: 2,
          name: '基础信息'
        },
        {
          index: 3,
          name: '企业信息'
        },
        {
          index: 4,
          name: '银行信息'
        },
        {
          index: 5,
          name: '店铺信息'
        }
      ]
    }
  },
  watch: {
    $route (to) {
      this.step = to.query.step
    }
  },
  mounted () {
    let step = this.$route.query.step
    step
      ? this.step = parseInt(step)
      : 1
  }
}
</script>

<style lang="less" scoped>
  @import "index";
</style>

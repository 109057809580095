<template>
  <div class="contacts-form">
    <div class="form-wrapper">
      <h3>基础信息</h3>
      <div class="form-main">
        <el-form
          ref="contactForm"
          :rules="rules"
          :model="contact">
          <el-form-item label="公司名称" prop="enterpriseName">
            <el-input
              v-model.trim="contact.enterpriseName"
              maxlength="30"
              placeholder="请输入公司名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="name">
            <el-input
              v-model.trim="contact.name"
              maxlength="30"
              placeholder="请输入联系人姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="email">
            <el-input
              v-model.trim="contact.email"
              placeholder="请输入联系人邮箱"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人手机号码" prop="mobile">
            <el-input
              v-model.trim="contact.mobile"
              maxlength="11"
              @input="contact.mobile = contact.mobile.replace(/[^\d]/g, '')"
              placeholder="请输入联系人手机号码"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <ul class="footer-btns">
      <li @click="preStep">上一步</li>
      <li @click="nextStep" class="active">下一步</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      contact: {
        enterpriseName: '',
        name: '',
        mobile: '',
        email: ''
      },
      // 校验规则
      rules: {
        enterpriseName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ],
        mobile: [
          { validator: this.$rules.checkPhone, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let contactInfo = JSON.parse(localStorage.getItem('contact_info'))
      if (contactInfo) {
        this.contact = contactInfo
      }
    },

    // 点击上一步
    preStep() {
      this.$router.push({
        name: 'apply',
        query: { step: 1 }
      })
    },

    // 点击下一步
    nextStep() {
      this.$refs.contactForm.validate(valid => {
        if (valid) {
          localStorage.setItem('contact_info', JSON.stringify(this.contact))
          this.$router.push({
            name: 'apply',
            query: { step: 3 }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "form";
</style>

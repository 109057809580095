<template>
  <div class="company-from">

    <div class="form-wrapper">
      <h3>身份证信息</h3>
      <div class="form-main">
        <el-form
          ref="ownerIdCardForm"
          :rules="ownerRules"
          :model="ownerIdCardInfo"
        >
          <el-form-item label="上传法人身份证(正反面)">
            <ul class="upload-items">
              <li>
                <el-upload
                  class="st-uploader upload_idcard_prev"
                  action="string"
                  :show-file-list="false"
                  :http-request="uploadIdCardPrev"
                >
                  <img
                    v-if="ownerIdCardInfo.idCardBackUrl"
                    :src="ownerIdCardInfo.idCardBackUrl"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="idCardPrevLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>身份证头像面</h4>
              </li>
              <li>
                <el-upload
                  class="st-uploader upload_idcard_back"
                  action=""
                  :show-file-list="false"
                  :http-request="uploadIdCardBack"
                >
                  <img
                    v-if="ownerIdCardInfo.idCardFrontUrl"
                    :src="ownerIdCardInfo.idCardFrontUrl"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="idCardBackLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>身份证国徽面</h4>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="法人姓名" prop="name">
            <el-input
              v-model="ownerIdCardInfo.name"
              maxlength="6"
              placeholder="请输入法人姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idCardNum">
            <el-input
              v-model="ownerIdCardInfo.idCardNum"
              maxlength="18"
              placeholder="请输入身份证号码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="身份证有效期" prop="idCardExpired">
            <el-input
              v-model="ownerIdCardInfo.idCardExpired"
              placeholder="格式：2020.01.01-2020.10.01 或者 2020.01.01-长期"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="form-wrapper">
      <h3>手持身份证照</h3>
      <div class="form-main">
        <el-form ref="form" :model="ownerIdCardInfo">
          <el-form-item label="上传手持身份证(正反面)">
            <ul class="upload-items">
              <li>
                <el-upload
                  class="st-uploader upload_hand_prev"
                  action=""
                  :show-file-list="false"
                  :http-request="uploadHandPrev"
                >
                  <img
                    v-if="ownerIdCardInfo.holdingIdCardBackUrl"
                    :src="ownerIdCardInfo.holdingIdCardBackUrl"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="handPrevLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>手持身份证头像照</h4>
              </li>
              <li>
                <el-upload
                  class="st-uploader upload_hand_back"
                  action=""
                  :show-file-list="false"
                  :http-request="uploadHandBack"
                >
                  <img
                    v-if="ownerIdCardInfo.holdingIdCardFrontUrl"
                    :src="ownerIdCardInfo.holdingIdCardFrontUrl"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="handBackLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>手持身份证反面照</h4>
              </li>
            </ul>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="form-wrapper">
      <h3>营业执照信息</h3>
      <div class="form-main">
        <el-form
          ref="enterpriseForm"
          :rules="enterRules"
          :model="enterpriseInfo"
        >
          <el-form-item label="上传营业执照图片">
            <ul class="upload-items">
              <li>
                <el-upload
                  class="st-uploader upload_license"
                  action=""
                  :show-file-list="false"
                  :http-request="uploadLicimg"
                >
                  <img
                    v-if="enterpriseInfo.licImg"
                    :src="enterpriseInfo.licImg"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="licimgLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>营业执照照片</h4>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="公司名称" prop="name">
            <el-input
              v-model="enterpriseInfo.name"
              maxlength="30"
              placeholder="请输入公司名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码（或税务登记证号）" prop="tax">
            <el-input
              v-model="enterpriseInfo.tax"
              maxlength="18"
              placeholder="请输入统一社会信用代码（或税务登记证号）"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="住所" prop="addr">
            <el-input
              v-model="enterpriseInfo.addr"
              maxlength="40"
              placeholder="请输入营业执照的住所地址"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <ul class="footer-btns">
      <li @click="preStep">上一步</li>
      <li @click="nextStep" class="active">下一步</li>
    </ul>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: '',
  data () {
    return {
      ownerIdCardInfo: {
        idCardBackUrl: '', // 法人身份证头像面
        idCardFrontUrl: '', // 法人身份证国徽面
        holdingIdCardBackUrl: '', // 法人手持身份证头像面
        holdingIdCardFrontUrl: '', // 法人手持身份证国徽面
        name: '', // 身份证姓名
        idCardNum: '', // 身份证号码
        idCardExpired: '' // 身份证有效时间
      },
      enterpriseInfo: {
        licImg: '', // 营业执照图片
        name: '', // 公司名称（营业执照）
        tax: '', // 公司税号
        addr: '', // 公司地址
        bank: '', // 开户行
        account: '', // 银行账号
        accountOwner: '', // 开户人姓名
        accountMobile: '' // 银行预留手机号
      },
      // 加载动画
      idCardPrevLoading: false,
      idCardBackLoading: false,
      handPrevLoading: false,
      handBackLoading: false,
      licimgLoading: false,

      // 校验规则
      ownerRules: {
        name: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 6 个字符', trigger: 'blur' }
        ],
        idCardNum: [
          { validator: this.$rules.checkIdCard, trigger: 'blur' }
        ],
        idCardExpired: [
          { required: true, message: '请输入身份证有效期', trigger: 'blur' }
        ]
      },
      enterRules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        tax: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        addr: [
          { required: true, message: '请输入营业执照中的住所地址', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions({
      uploadImage: "uploadImage",
      idCardOcr: "idCardOcr",
      businessLicenseOcr: "businessLicenseOcr"
    }),

    // 初始化
    init() {
      let ownerIdCardInfo = JSON.parse(localStorage.getItem('owner_idcard_info'))
      let enterpriseInfo = JSON.parse(localStorage.getItem('enterprise_info'))
      if (ownerIdCardInfo) {
        this.ownerIdCardInfo = ownerIdCardInfo
      }
      if (enterpriseInfo) {
        this.enterpriseInfo = enterpriseInfo
      }
    },

    // 上传身份证头像面
    uploadIdCardPrev(event) {
      this.idCardPrevLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          if (res) {
            // 身份证识别
            this.idCardOcr({
              typeId: '2',
              file: event.file
            }).then(r => {
                this.idCardPrevLoading = false
                if (r) {
                  this.ownerIdCardInfo.idCardBackUrl = res.imgUrl
                  this.ownerIdCardInfo.name = r.name
                  this.ownerIdCardInfo.idCardNum = r.id
                  localStorage.setItem('owner_idcard_info', JSON.stringify(this.ownerIdCardInfo))
                  localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
                }
              })
              .catch(() => {
                this.idCardPrevLoading = false
                this.$message.error('上传身份证头像面失败')
              })
          }
        })
        .catch(() => {
          this.idCardPrevLoading = false
          this.$message.error('上传身份证头像面失败')
        })
    },
    // 上传身份证国徽面
    uploadIdCardBack(event) {
      this.idCardBackLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          if (res) {
            // 身份证识别
            this.idCardOcr({
              typeId: '3',
              file: event.file
            }).then(r => {
                this.idCardBackLoading = false
                if (r) {
                  let issuingDate = moment(r.issuingDate).format('YYYY.MM.DD')
                  let overdueDate = r.overdueDate
                  overdueDate !== '长期'
                    ? (overdueDate = moment(r.overdueDate).format('YYYY.MM.DD'))
                    : ''
                  this.ownerIdCardInfo.idCardFrontUrl = res.imgUrl
                  this.ownerIdCardInfo.idCardExpired = issuingDate + '-' + overdueDate
                  localStorage.setItem('owner_idcard_info', JSON.stringify(this.ownerIdCardInfo))
                  localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
                }
              })
              .catch(() => {
                this.idCardBackLoading = false
                this.$message.error('上传身份证国徽面失败')
              })
          }
        })
        .catch(() => {
          this.idCardBackLoading = false
          this.$message.error('上传身份证国徽面失败')
        })
    },
    // 上传法人手持身份证头像面
    uploadHandPrev(event) {
      this.handPrevLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          this.handPrevLoading = false
          if (res) {
            this.ownerIdCardInfo.holdingIdCardBackUrl = res.imgUrl
          }
        })
        .catch(() => {
          this.handPrevLoading = false
          this.$message.error('上传法人手持身份证头像面失败')
        })
    },
    // 上传法人手持身份证国徽面
    uploadHandBack(event) {
      this.handBackLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          this.handBackLoading = false
          if (res) {
            this.ownerIdCardInfo.holdingIdCardFrontUrl = res.imgUrl
          }
        })
        .catch(() => {
          this.handBackLoading = false
          this.$message.error('上传法人手持身份证国徽面失败')
        })
    },
    // 上传营业执照图片
    uploadLicimg(event) {
      this.licimgLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          if (res) {
            this.businessLicenseOcr({ file: event.file }).then(r => {
                if (r) {
                  this.licimgLoading = false
                  this.enterpriseInfo.licImg = res.imgUrl
                  this.enterpriseInfo.name = r.name
                  this.enterpriseInfo.tax = r.unifiedSocialCreditCode || r.taxRegistrationNumber
                  this.enterpriseInfo.addr = r.address;
                  localStorage.setItem('owner_idcard_info', JSON.stringify(this.ownerIdCardInfo))
                  localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
                }
              })
              .catch(() => {
                this.licimgLoading = false
                this.$message.error('上传营业执照图片失败')
              })
          }
        })
        .catch(() => {
          this.licimgLoading = false
          this.$message.error('上传营业执照图片失败')
        })
    },

    // 点击上一步
    preStep() {
      localStorage.setItem('owner_idcard_info', JSON.stringify(this.ownerIdCardInfo))
      localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
      this.$router.push({
        name: 'apply',
        query: { step: 2 }
      })
    },
    // 点击下一步
    nextStep() {
      if (this.checkImages()) {
        this.$refs.ownerIdCardForm.validate(valid => {
          if (valid) {
            this.$refs.enterpriseForm.validate(v => {
              if (v) {
                localStorage.setItem('owner_idcard_info', JSON.stringify(this.ownerIdCardInfo))
                localStorage.setItem('enterprise_info', JSON.stringify(this.enterpriseInfo))
                this.$router.push({
                  name: 'apply',
                  query: { step: 4 }
                })
              } else {
                this.$message.error('营业执照信息不全')
                return false;
              }
            });
          } else {
            this.$message.error('身份证信息不全')
            return false
          }
        })
      }
    },
    // 检测证件图片是否上传
    checkImages() {
      if (_.isEmpty(this.ownerIdCardInfo.idCardBackUrl)) {
        this.$message.error('请上传法人身份证头像面')
        return false
      } else if (_.isEmpty(this.ownerIdCardInfo.idCardFrontUrl)) {
        this.$message.error('请上传法人身份证国徽面')
        return false
      } else if (_.isEmpty(this.ownerIdCardInfo.holdingIdCardBackUrl)) {
        this.$message.error('请上传法人手持身份证头像面')
        return false
      } else if (_.isEmpty(this.enterpriseInfo.licImg)) {
        this.$message.error('请上传营业执照图片')
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import "form";
</style>

export const agreement = `
<dl class="agree-items">
  <dt>第一条 总则</dt>
  <dd>
    <p>1.1 欢迎您与食神通大掌柜共同签署本《用户服务协议》（下称“本协议”）并使用食神通大掌柜相关服务。</p>
  </dd>
  <dd>
    <p>1.2 各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
  </dd>
  <dd>
    <p>1.3 您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。 请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。 如您对协议有任何疑问，可向我方客服（0517-81656885）咨询。</p>
  </dd>
  <dd>
    <p>1.4 当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我方达成一致，成为我方“用户”。 阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</p>
  </dd>
  <dd>
    <p>1.5 如果您未申请注册流程，或者在本协议生效前已成为我方的注册用户，则您名下任何账户通过访问和/或使用我方系统、网站、应用程序及其他各类端口，即视为您表示同意接受本协议的全部内容，否则请您不要访问或使用我方服务。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第二条 定义</dt>
  <dd>
    <p>2.1 食神通大掌柜系统：指北京新大陆智能溯源科技有限公司（以下简称：新大陆溯源公司）合法所有或运营的网站、客户端、应用程序等系统。</p>
  </dd>
  <dd>
    <p>2.2 食神通大掌柜服务：食神通大掌柜基于互联网，以包含客户端、网站等在内的各种形态（包括未来技术发展出现的新的服务形态）向用户提供的各项服务。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第三条 协议范围</dt>
  <dd>
    <p>3.1 签约主体</p>
    <ul>
      <li>本协议由您与新大陆溯源公司共同缔结，本协议对您与新大陆溯源公司均具有法律效力。</li>
    </ul>
  </dd>
  <dd>
    <p>3.2 补充协议</p>
    <ul>
      <li>3.2.1 由于互联网行业高速发展，您与我们签署的本协议列明的条款并不能完整罗列并覆盖您与食神通大掌柜所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，我们公示的相关声明及政策、规则和协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用我们的服务，视为您同意上述补充协议。</li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第四条 账号注册与使用</dt>
  <dd>
    <p>4.1 用户资格</p>
    <ul>
      <li>4.1.1 您确认，在您开始使用/注册程序使用食神通大掌柜服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</li>
      <li>4.1.2 如果您是未成年人，请在您的监护人的同意和指导下访问和/或使用我们的服务。</li>
    </ul>
  </dd>
  <dd>
    <p>4.2 账号说明</p>
    <ul>
      <li>4.2.1 当您按照注册页面提示填写信息、阅读并同意本协议及食神通大掌柜《隐私权政策》且完成全部注册程序后，您可获得食神通大掌柜账号并成为我们食神通大掌柜系统的用户。</li>
      <li>4.2.2 鉴于新大陆溯源公司旗下各餐饮服务相关应用有着密切不可分割之关联，各应用主要功能需相互配合方可为您提供对应的服务，因此，如您使用同一账户登录新大陆溯源公司旗下各应用的，您理解并授权您所登录的新大陆溯源公司旗下各应用之间互相共享您提供的或各应用收集的您的个人信息以及您使用各应用时产生的操作及交易信息。前述新大陆溯源公司旗下应用包括但不限于食神通大掌柜、食神通扫码点餐、厨神通、财神通供应链等，同一手机号码、微信号、支付宝账户、账户ID均被视为同一账户。</li>
      <li>4.2.3 您有权使用您设置或确认的我方用户名、邮箱、手机号码（以下简称“账号名称”）及您设置的密码（账号名称及密码合称“账号”）登录食神通大掌柜系统。</li>
      <li>4.2.4 由于用户账号关联用户信息，仅当有法律明文规定、司法裁定或经我方同意，并符合我方相关规则规定的用户账号转让流程的情况下，您才可进行帐户的转让。您的账号一经转让，该账号项下权利义务一并转移。除此以外，您的账号不得以任何方式转让，否则由此产生的一切责任均由您承担。</li>
      <li>4.2.5 为使您更好地使用我方的各项服务，我们建议您按照相关法律法规规定及我们产品页面的提示完成实名认证或资质认证。</li>
      <li>4.2.6 如您的账号长期未登录，我方有权予以进行注销等清理，您的账号将不能再登录任一我方系统终端，相应服务同时终止。我方在对此类账号进行清理前，将以包括但不限于网站公告、站内消息、客户端推送信息等方式通知您。</li> 
    </ul>
  </dd>
  <dd>
    <p>4.3 注册信息管理</p>
    <ul>
      <li>
        <dl class="sub-items">
          <dt>4.3.1 真实合法</dt>
          <dd>4.3.1.1 在使用我方服务时，您应当按我方系统或产品页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便我方在必要时与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。</dd>
          <dd>4.3.1.2 您所设置的用户名不得违反国家法律法规及我方关于用户名的管理规定，否则我方可对您的用户名进行暂停使用或注销等处理，并向主管机关报告。</dd>
          <dd>4.3.1.3 您理解并承诺，您的用户名称、头像和简介等注册信息中不得出现违法和不良信息，没有冒用、关联机构或社会名人，您在账号注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。</dd>
        </dl>
      </li>
      <li>
        <dl class="sub-items">
          <dt>4.3.2 更新维护</dt>
          <dd>4.3.2.1 您应当及时更新您提供的信息，在法律有明确规定要求我方对部分用户的信息进行核实的情况下，我方将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整的信息。</dd>
          <dd>4.3.2.2 如我方按您最后一次提供的信息与您联系未果、您未按照我方的要求及时提供信息、您提供的信息存在明显不实的，您将承担因此对您自身、他人及我方造成的全部损失与不利后果。</dd>
          <dd>4.3.2.3 我方对于您个人信息的收集、使用、保护等内容详见《隐私权政策》。</dd>
        </dl>
      </li>
    </ul>
  </dd>
  <dd>
    <p>4.4 账号安全规范</p>
    <ul>
      <li>4.4.1 您的账号为您自行设置并由您保管。建议您务必保管好您的帐户（如您为非自然人用户，请您同时加强内部人员管理，确保您的工作人员能妥善保管您的账户信息及账户密码安全），并确保您在每个上网时段结束时退出登录并以正确步骤离开我方系统。</li>
      <li>4.4.2 账号因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。</li>
      <li>4.4.3 您的账号只限您本人或您指定的下属员工使用，不得出借或分享他人使用。当您的账号遭到未经授权的使用时，您应当立即通知我们，否则未经授权的使用行为均视为您本人的行为，您将自行承担所有由此导致的损失及后果。</li>
      <li>4.4.4 除我方存在过错外， 您应对您账号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</li>
      <li>4.4.5 如发现任何未经授权使用您账号登录我方系统或其他可能导致您账号遭窃、遗失的情况，建议您立即通知我方。您理解我方对您的任何请求采取行动均需要合理时间，除我方存在主观过错外，我方对在采取行动前已经产生的后果不承担任何责任。</li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第五条 食神通大掌柜服务内容及规范</dt>
  <dd>
    <p>5.1 食神通大掌柜提供的服务</p>
    <ul>
      <li>5.1.1 我方提供的食神通大掌柜服务均仅限于您在食神通大掌柜系统或平台使用，未经我方明确同意或安排，任何以恶意破解等非法手段将我方服务与我方公司分离的行为，均不属于本协议中约定的我方服务。由此引起的一切法律后果由行为人负责，我方将依法追究行为人的法律责任。</li>
      <li>5.1.2 除了我方明确可由第三方授权登录我方相关客户端外，我方网站官方公布的方式为下载、注册、登录、使用我方服务的唯一合法方式，您通过其他未经我方明确认可的渠道、途径、方式获取的我方服务（包括且不限于账号、积分、经验值、积分商品、兑换商品、赠品、下载等）均为非法取得，我方概不承认其效力，且一经发现我方有权立即做出删除、取消、清零、封号等处理，任何因此导致的一切不利后果均由您自行承担。</li>
      <li>5.1.3 我方有权向您公告（包括但不限于弹出页面、网站公告、站内消息、短信、邮件通知等方式）以修改、替换、升级与我方服务相关的任何软件、系统或服务。如果您不同意或者不接受我方软件、系统或服务的修改、替换、升级，请直接拒绝、停止、取消，否则视为您同意并接受我方软件、系统或服务的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。</li> 
      <li>5.1.4 您理解并认可，您使用我方系统或我方账号所获得的使用记录、订阅、收藏、积分、经验值、成长值、等级、身份标识、赠券、虚拟积分商品、虚拟兑换商品、虚拟赠品、下载等衍生物，您确认对其不享有所有权（除非我方另有公告说明），我方许可您按照我方规则进行使用，我方对上述衍生物不承担任何赔偿责任。</li>
      <li>5.1.5 您同意并保证，不得利用我方服务或其衍生物（包括但不限于账号、积分、等级、积分商品、活动赠品、下载等服务）进行倒卖、转手、置换、抵押有价交易等方式非法牟利。您不会利用我方服务或其衍生物侵犯他人的合法权益，禁止通过网络漏洞、恶意软件或其他非法手段窃取、盗用他人的帐户、积分、积分商品等。</li>
      <li>5.1.6 您理解并认可，如果您通过第三方支付工具在我方账号支付或充值后可能产生的任何商业风险（包括但不限于不法分子利用您帐户或银行卡等有价卡等进行违法活动），该等风险均有可能给您造成相应的经济损失，我方在充分履行其在本协议项下义务和符合法律规定的前提下，不对您的前述风险和损失承担任何责任。</li>
    </ul>
  </dd>
  <dd>
    <p>5.2 服务规范</p>
    <ul>
      <li>5.2.1 您可通过我方系统服务在我方系统各类用户端上传、发布或传输相关内容，包括但不限于文字、软件、程序、图形、图片、声音、音乐、视频、音视频、链接等信息或其他资料（下称“内容”），但您需对此内容承担相关的法律责任。</li>
      <li>5.2.2 除非有相反证明，我方将您视为您在我方系统上传、发布或传输的内容的版权拥有人。您使用我方服务上传、发布或传输内容即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予我方对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；对您的上传、发布的内容进行商业开发的权利；通过有线或无线网络向您的计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑、穿戴智能设备等）、手持数字影音播放设备、电视接收设备（模拟信号接收设备、数字信号接收设备、数字电视、IPTV、带互联网接入功能的播放设备等）等提供信息的下载、点播、数据传输、移动视频业务（包括但不限于SMS、MMS、WAP、IVR、Streaming、3G、4G、5G、手机视频等无线服务）、及其相关的宣传和推广等服务的权利；以及再授权给其他第三方以上述方式使用的权利。</li>
      <li>5.2.3 为方便您使用食神通大掌柜服务及食神通大掌柜与其他合作伙伴共同向您提供的服务，您授权我方在您注册和使用我方服务期间提供、形成的信息传递给我方的合作伙伴及其他相关方，或从其他相关方获取您在注册、使用相关服务期间提供、形成的信息。</li> 
      <li>5.2.4 您理解并知晓在使用我方服务时，所接触的内容和信息来源广泛，我方无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。您理解并认可您可能会接触到不正确的、令人不快的、不适当的或令人厌恶的内容和信息，您不会以此追究我方的相关责任。我方不对用户在我方系统上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。但如您认为其他用户所上传、发布、传输的任何内容侵犯到您的合法权益，您可立即通知我们，我们会按照法律法规规定及我们与用户的约定进行相应处理。</li>
      <li>5.2.5 您同意我方在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在我方的任何位置上投放广告，在您上传、传播的内容中投放广告），您同意接受我方通过电子邮件、站内短信、手机短信、微信、网站公告或其他方式向您发送促销或其他相关商业信息。</li>
      <li>5.2.6 您同意在使用我方服务过程中，遵守以下法律法规：《保守国家秘密法》、《著作权法》、《计算机信息系统安全保护条例》、《计算机软件保护条例》[《互联网电子公告服务管理规定》已失效。]、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规。在任何情况下，我方一旦合理地认为您的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向您提供服务。您应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。</li>
    </ul>
  </dd>
  <dd>
    <p>5.3 禁止的内容</p>
    <ul>
      <li>
        <dl>
          <dt>5.3.1 您理解并保证您在我方上传、发布或传输的内容（包括您的账号名称等信息）不含有以下内容：</dt>
          <dd>（一）反对宪法确定的基本原则的；</dd>
          <dd>（二）危害国家统一、主权和领土完整的；</dd>
          <dd>（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</dd>
          <dd>（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</dd>
          <dd>（五）宣扬邪教、迷信的；</dd>
          <dd>（六）扰乱社会秩序，破坏社会稳定的；</dd>
          <dd>（七）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</dd>
          <dd>（八）侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；</dd>
          <dd>（九）危害社会公德，损害民族优秀文化传统的；</dd>
          <dd>（十）有关法律、行政法规和国家规定禁止的其他内容。</dd>
        </dl>
      </li>
      <li>5.3.2 如果您上传、发布或传输的内容含有以上违反法律法规的信息或内容的，或者侵犯任何第三方的合法权益，您将直接承担因此导致的一切不利后果。如因此给我方造成不利后果的，您应负责消除影响，并且赔偿我方因此导致的一切损失，包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。</li>
    </ul>
  </dd>
  <dd>
    <p>5.4 禁止的行为</p>
    <ul>
      <li>
        <dl>
          <dt>5.4.1 您理解并保证不就我方服务进行下列的禁止的行为，也不允许任何人利用您的账号进行下列行为：</dt>
          <dd>（一）在注册账号时，或使用我方服务时，冒充他人，或您讹称与任何人或实体有联系（包括设置失实的帐户名称或接入另一用户的账号）；</dd>
          <dd>（二）伪造标题或以其他方式操控内容，使他人误认为该内容为我方所传输；</dd>
          <dd>（三）将无权传输的内容（例如内部资料、机密资料）进行上传、发布、发送电子邮件或以其他方式传输；</dd>
          <dd>（四）发送任何未获邀约或未经授权的垃圾电邮、广告或宣传资料，或任何其他商业通讯；</dd>
          <dd>（五）未经我方明确许可，使用我方服务用于任何商业用途或为任何第三方的利益；</dd>
          <dd>（六）跟踪或以其他方式骚扰他人；</dd>
          <dd>（七）参与任何非法或有可能非法（由我方判断）的活动或交易，包括传授犯罪方法、出售任何非法药物、洗钱活动、诈骗等；</dd>
          <dd>（八）赌博、提供赌博数据或透过任何方法诱使他人参与赌博活动；</dd>
          <dd>（九）使用或利用我方知识产权（包括我们的商标、品牌、标志、任何其他专有数据或任何网页的布局或设计），或在其他方面侵犯我方任何知识产权（包括试图对我方客户端或所使用的软件进行逆向工程）；</dd>
          <dd>（十）通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入我方服务、收集或处理通过我方服务所提供的内容；</dd>
          <dd>（十一）参与任何“框架”、“镜像”或其他技术，目的是模仿我方服务的外观和功能；</dd>
          <dd>（十二）干预或试图干预任何用户或任何其他方接入我方服务；</dd>
          <dd>（十三）故意散播病毒、网络蠕虫、特洛伊木马病毒、已损毁的档案或其他恶意代码或项目；</dd>
          <dd>（十四）未经他人明确同意，分享或发布该等人士可识别个人身份的资料；</dd>
          <dd>（十五）探究或测试我方服务、系统或其他用户的系统是否容易入侵攻击，或在其他方面规避（或试图规避）我方服务、系统或其他用户的系统的任何安全功能；</dd>
          <dd>（十六）对我方服务所用的软件进行解编、反向编译或逆向工程，或试图作出上述事项；</dd>
          <dd>（十七）为破坏或滥用的目的开设多个账号，或恶意上传重复的、无效的大容量数据和信息；</dd>
          <dd>（十八）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。</dd>
        </dl>
      </li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第六条 第三方链接</dt>
  <dd>
    <p>我方服务可能会包含与其他网站或资源的链接，如支付宝、微信等。我方对于前述网站或资源的内容、隐私政策和活动，无权控制、审查或修改，因而也不承担任何责任。我方建议您在离开我方系统，访问其他网站或资源前仔细阅读其服务条款和隐私政策。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第七条 信息收集及使用</dt>
  <dd>
    <p>7.1 信息的收集</p>
    <ul>
      <li>7.1.1 为了向用户提供更好、更优、更个性化的服务，您同意并授权食神通大掌柜根据本协议及其他法律法规合法、正当地收集并使用您在食神通大掌柜系统上所留下的与食神通大掌柜服务相关的必要信息。</li>
      <li>7.1.2 当您在食神通大掌柜系统创建账户使用服务或通过第三方平台授权登陆时，食神通大掌柜会记录您向食神通大掌柜提供的各类信息，包括但不限于地理位置、手机号、相应的店铺信息名称、店铺地址、员工信息、菜品类目、价格、经营数据、营销数据、用户消费数据等信息；同时，食神通大掌柜还会记录您在使用食神通大掌柜服务时自愿提供的信息，例如消费金额、所点菜品、您在食神通大掌柜系统用户终端发布的信息、搜索关键词信息、您与食神通大掌柜客服联系时所提供的相关信息、您参与店铺测评时向食神通大掌柜发送的各类答复信息。</li>
      <li>7.1.3 更多关于用户个人信息的收集内容详见食神通大掌柜《隐私权政策》。</li> 
    </ul>
  </dd>
  <dd>
    <p>7.2 信息的使用</p>
    <ul>
      <li>
        <dl>
          <dt>7.2.1 当您与食神通大掌柜或食神通大掌柜第三方合作伙伴之间互动时，本着共同向用户提供服务及改进服务质量的合理需要，也为了风险防控，以及保护各方的合法权益，用户同意食神通大掌柜或第三方合作伙伴在必要时将以上必要信息用于如下用途：</dt>
          <dd>7.2.1.1 在您使用食神通大掌柜服务过程中，为向您提供定制化服务，并持续维护、改善、优化这些服务，包括但不限于对您的信息进行分析和处理、或将您的信息用于数据建模、大数据分析等；</dd>
          <dd>7.2.1.2 用于预防、发现、调查欺诈、危害安全、非法或违反与食神通大掌柜协议、政策或规则的行为，以保护您、食神通大掌柜其他用户食神通大掌柜关联方或第三方合作伙伴的合法权益；</dd>
        </dl>
      </li>
      <li>7.2.2 我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解您如何使用食神通大掌柜服务或让公众了解食神通大掌柜服务的总体使用趋势；</li>
      <li>7.2.3 我们可能会将某项服务的信息与来自其他服务的信息结合起来，以便为您提供更优质、个性化的服务、内容和建议。</li> 
      <li>7.2.4 如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规、及本协议要求。另外，如我们需从第三方处获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性。同时，我们也会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。</li>
      <li>7.2.5 我们会根据相关法律法规及监管规定对您的信息承担保护义务，并对关联公司或第三方合作伙伴进行数据安全能力审核，要求他们按照本协议及相关隐私政策采取保密和安全措施。</li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第八条 知识产权</dt>
  <dd>
    <p>8.1 除非另有约定或我方另行声明，我方的所有内容（用户依法享有版权的内容除外）、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利，均归我方所有。未经我方许可，任何人不得擅自使用（包括但不限于复制、传播、展示、镜像、上载、下载、修改、出租）。</p>
  </dd>
  <dd>
    <p>8.2 食神通大掌柜的Logo、品牌等文字、图形及其组合，以及我方的其他标识、徵记、产品和服务名称均为我方在中国或其它国家的合法权利，未经我方书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。</p>
  </dd>
  <dd>
    <p>8.3 我方对我方专有内容、原创内容和其他通过授权取得的独占或独家内容享有完全知识产权。未经我方许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯我方知识产权的行为，否则将承担所有相关的法律责任。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第九条 用户的违约及处理</dt>
  <dd>
    <p>9.1 违约认定</p>
    <p>发生如下情形之一的，视为您违约：</p>
    <ul>
      <li>9.1.1 使用我方服务时违反有关法律法规规定的；</li>
      <li>9.1.2 违反本协议或本协议补充协议约定的。</li>
    </ul>
  </dd>
  <dd>
    <p>9.2 为适应互联网行业发展和满足海量用户对高效优质服务的需求，您理解并同意， 我方可在我方规则中约定违约认定的程序和标准。如：我方可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</p>
  </dd>
  <dd>
    <p>9.3 违约处理措施</p>
    <ul>
      <li>9.3.1 您在我方发布的内容和信息构成违约的， 我方可根据相应规则立即对相应内容和信息进行删除、屏蔽等处理或对您的账号进行暂停使用、查封、注销等处理。</li>
      <li>9.3.2 您在我方系统上实施的行为，或虽未在我方系统上实施但对我方系统及其他用户产生影响的行为构成违约的，我方可依据相应规则对您的账号执行限制参加活动、中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，我方可查封您的账号，终止向您提供服务。</li>
      <li>9.3.3 如果您在我方系统上的行为违反相关的法律法规，我方可依法向相关主管机关报告并提交您的使用记录和其他信息。</li> 
    </ul>
  </dd>
  <dd>
    <p>9.4 赔偿责任</p>
    <ul>
      <li>9.4.1 如您的行为使我方遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等经济损失），您应赔偿我方的上述全部损失。</li>
      <li>9.4.2 如您的行为使我方遭受第三人主张权利，我方可在对第三人承担金钱给付等义务后就全部损失向您追偿。</li>
    </ul>
  </dd>
  <dd>
    <p>9.5 特别约定</p>
    <ul>
      <li>9.5.1 我方负责"按现状"和"可得到"的状态向您提供我方服务。我方依法律规定承担相应义务，但无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，黑客活动、计算机病毒、电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</li>
      <li>9.5.2 我方通过中华人民共和国境内的设施控制和提供我方服务，我方不担保控制或提供的服务在其他国家或地区是适当的、可行的，任何在其他司法辖区使用我方服务的用户应自行确保其遵守当地的法律法规，我方对此不承担任何责任。</li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第十条 协议的变更</dt>
  <dd>
    <p>10.1 我方可根据国家法律法规变化及我方服务变化的需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过本协议约定的方式通知您。</p>
  </dd>
  <dd>
    <p>10.2 如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用我方服务；如您在变更事项生效后仍继续使用我方服务，则视为您同意已生效的变更事项。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第十一条 通知</dt>
  <dd>
    <p>您同意我方以以下合理的方式向您送达各类通知：</p>
    <ul>
      <li>（一）公示的文案；</li>
      <li>（二）站内消息、弹出消息、客户端推送的消息；</li>
      <li>（三）根据您预留于我方系统的联系方式发出的电子邮件、手机短信、微信、函件等。</li> 
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第十二条 协议的终止</dt>
  <dd>
    <p>12.1 终止的情形</p>
    <p>您有权通过以下任一方式终止本协议：</p>
    <ul>
      <li>（一）在满足我方公示的账号注销等清理条件时您通过网站注销您的账号的；</li>
      <li>（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</li>
      <li>（三）您明示不愿继续使用我方服务，且符合终止条件的。</li> 
    </ul>
  </dd>
  <dd>
    <p>出现以下情况时，我方可以本协议所列的方式通知您终止本协议：</p>
    <ul>
      <li>（一）您违反本协议约定，我方依据违约条款终止本协议的；</li>
      <li>（二）您转让本人账号、盗用他人账号、发布违禁内容和信息、骗取他人财物、采取不正当手段谋利等行为，我方依据我方规则对您的账号予以查封的；</li>
      <li>（三）除上述情形外，因您多次违反我方规则相关规定且情节严重，我方依据我方规则对您的账号予以查封的；</li>
      <li>（四）您的账号被我方依据本协议进行注销等清理的；</li>
      <li>（五）您在我方有侵犯他人合法权益或其他严重违法违约行为的；</li> 
      <li>（六）其它根据相关法律法规我方应当终止服务的情况。</li>
    </ul>
  </dd>
  <dd>
    <p>12.2 协议终止后的处理</p>
    <p>本协议终止后，除法律有明确规定外，我方无义务向您或您指定的第三方披露您帐户中的任何信息。</p>
    <p>本协议终止后，我方享有下列权利：</p>
    <ul>
      <li>（一）停止收集和使用您的个人信息，但可继续保存您留存于我方系统的其他内容和信息；</li>
      <li>（二）对于您过往的违约行为，我方仍可依据本协议向您追究违约责任。</li>
    </ul>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第十三条 法律适用、管辖与其他</dt>
  <dd>
    <p>13.1 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
  </dd>
  <dd>
    <p>13.2 您因使用我方服务所产生及与我方服务有关的争议，由我方与您协商解决。协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。</p>
  </dd>
  <dd>
    <p>13.3 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
  </dd>
</dl>

<dl class="agree-items">
  <dt>第十四条 完整协议</dt>
  <dd>
    <p>本协议由《用户服务协议》条款与我方公示的各项规则组成，各项规则有约定，而本协议条款没有约定的，以各项规则约定为准。本协议部分内容被有管辖权的机构认定为违法或无效的，不因此影响其他内容的效力。</p>
  </dd>
</dl>
`

<template>
  <div class="agreement-form">
    <div class="form-wrapper">
      <h3>注册协议</h3>
      <div class="agreement-main">
        <div class="agreement-text">
          <div v-html="agreement"></div>
        </div>
        <div class="checked-item">
          <el-checkbox v-model="isRead">我已阅读并同意</el-checkbox>
        </div>
      </div>
    </div>
    <ul class="footer-btns">
      <li @click="nextStep" class="active">下一步</li>
    </ul>
  </div>
</template>

<script>
import { agreement } from '../../../../assets/js/agreement'
export default {
  name: '',
  data () {
    return {
      agreement: agreement,
      isRead: false // 是否已勾选
    }
  },
  methods: {
    // 点击下一步
    nextStep() {
      this.isRead == false
        ? this.$message.error('未同意协议')
        : this.$router.push({
            name: 'apply',
            query: { step: 2 }
          })
    }
  }
}
</script>

<style lang="less">
  @import "../form";
  @import "./index";
</style>

<template>
  <div class="shop-from">
    <div class="form-wrapper">
      <h3>店铺信息</h3>
      <div class="form-main">
        <el-form ref="shopForm" :rules="rules" :model="shopInfo">
          <el-form-item label="店铺招牌(门头照)">
            <ul class="upload-items">
              <li>
                <el-upload
                  class="st-uploader"
                  action=''
                  :show-file-list="false"
                  :http-request="uploadShopImg"
                >
                  <img
                    v-if="shopInfo.shopImg"
                    :src="shopInfo.shopImg"
                    class="avatar"
                  />
                  <i v-else class="add-icon"></i>
                  <div v-if="shopImgLoading" class="upload-loading">
                    <div>
                      <i class="el-icon-loading"></i>
                      <p>正在上传...</p>
                    </div>
                  </div>
                </el-upload>
                <h4>店铺门头照</h4>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="店铺名称" prop="name">
            <el-input
              v-model="shopInfo.name"
              maxlength="20"
              placeholder="请输入店铺名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="店铺网址（非必填）">
            <el-input v-model="shopInfo.website" placeholder="请输入店铺网址">
            </el-input>
          </el-form-item>
          <el-form-item label="店铺地址" class="address-items">
            <b class="search-btn" @click="searchDialogBtn">
              <span>搜索地址</span>
              <i class="el-icon-search"></i>
            </b>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item prop="address.province">
                  <el-select
                    v-model="shopInfo.address.province"
                    placeholder="请输入省"
                    @change="provinceChange"
                  >
                    <el-option
                      v-for="item in areas"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="address.city">
                  <el-select
                    v-model="shopInfo.address.city"
                    placeholder="请输入市"
                    @change="cityChange"
                  >
                    <el-option
                      v-for="item in cities"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="address.region">
                  <el-select
                    v-model="shopInfo.address.region"
                    placeholder="请输入区"
                  >
                    <el-option
                      v-for="item in regions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址" prop="address.street">
            <el-input
              v-model="shopInfo.address.street"
              placeholder="请输入详细地址"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="经纬度" class="address-items">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="address.position[0]">
                  <el-input
                    v-model="shopInfo.address.position[0]"
                    placeholder="请输入经度"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="address.position[1]">
                  <el-input
                    v-model="shopInfo.address.position[1]"
                    placeholder="请输入纬度"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <ul class="footer-btns">
      <li @click="preStep">上一步</li>
      <li @click="nextStep" class="active">下一步</li>
    </ul>

    <el-dialog
      :modal-append-to-body="false"
      title="搜索地址"
      :visible.sync="dialog.visible"
      width="1000px"
      class="st-dialog area-dialog"
    >
      <div class="search-item">
        <div class="search-form">
          <el-select v-model="searchForm.city" placeholder="请选择搜索城市">
            <el-option
              v-for="item in cityItems"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="searchForm.text"
            placeholder="请输入要搜索的关键字"
          >
          </el-input>
        </div>
        <el-button @click="placeSearch">立即搜索</el-button>
      </div>
      <div class="search-body">
        <ul class="address-items" v-if="searchAddressItems.length > 0">
          <li
            v-for="(item, index) in searchAddressItems"
            :key="index"
            :class="addressIndex === index ? 'active' : ''"
            @click="selectAddressItem(item, index)"
          >
            <span>[{{ item.name }}]</span> {{ item.cityname }}
            {{ item.adname }} {{ item.address }}
          </li>
        </ul>
        <div class="empty-holder" v-else>
          <span>没有相关地址</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="setShopAddress">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import AMap from 'AMap'
import { regionData } from 'element-china-area-data'
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: '',
  data () {
    return {
      // 地区数据
      areas: regionData,
      cities: [],
      regions: [],

      // 店铺表单
      shopInfo: {
        shopImg: '', // 店铺照片
        imgUrl: '', // 店铺头像
        name: '', // 店铺名称
        website: '', // 网址
        address: {
          province: '', // 省
          city: '', // 市
          region: '', // 区
          street: '', // 详细地址
          position: [], // 经纬度
          postCode: '' // 邮政编码
        }
      },
      shopImgLoading: false,

      // 弹窗
      dialog: {
        visible: false
      },
      cityItems: ['广州市', '深圳市', '杭州市'],
      searchForm: {
        city: '杭州市',
        text: ''
      },
      searchAddressItems: [],
      addressIndex: '',
      addressItem: {},

      // 校验规则
      rules: {
        name: [
          { required: true, message: '请输入店铺名称', trigger: 'blur' }
        ],
        'address.province': [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        'address.city': [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        'address.region': [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        'address.street': [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        'address.position[0]': [
          { required: true, message: '请输入经度', trigger: 'blur' }
        ],
        'address.position[1]': [
          { required: true, message: '请输入纬度', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions({
      uploadImage: 'uploadImage'
    }),

    // 初始化
    init() {
      let shopInfo = JSON.parse(localStorage.getItem('shop_info'))
      if (shopInfo) {
        this.shopInfo = shopInfo
        let address = shopInfo.address
        if (address && address.province && address.city) {
          this.provinceChange(address.province)
        }
        if (address && address.city && address.region) {
          this.cityChange(address.city)
        }
      }
    },

    // 上传店铺图片
    uploadShopImg(event) {
      this.shopImgLoading = true
      this.uploadImage({
        dir: 'profiles/full',
        file: event.file
      }).then(res => {
          this.shopImgLoading = false
          if (res) {
            this.shopInfo.shopImg = res.imgUrl
          }
        })
        .catch(() => {
          this.shopImgLoading = false
          this.$message.error('上传店铺照片失败')
        })
    },

    // 选择省份
    provinceChange(val) {
      this.cities = this.areas.find(item => item.label === val).children
    },
    // 选择城市
    cityChange(val) {
      this.regions = this.cities.find(item => item.label === val).children
    },

    // 点击搜索地址按钮
    searchDialogBtn() {
      this.dialog.visible = true
    },
    // 搜索地址
    placeSearch() {
      AMap.service(['AMap.PlaceSearch'], () => {
        let placeSearch = new AMap.PlaceSearch({
          city: this.searchForm.city, // 兴趣点城市
          citylimit: true, // 是否强制限制在设置的城市内搜索
          extensions: 'all',
          pageSize: 50
        })
        placeSearch.search(this.searchForm.text, (status, result) => {
          status === 'complete' && result.poiList
            ? (this.searchAddressItems = result.poiList.pois)
            : (this.searchAddressItems = [])
        })
      })
    },
    // 选择某个地址
    selectAddressItem(item, index) {
      this.addressIndex = index
      this.addressItem = {
        province: item.pname,
        city: item.cityname,
        region: item.adname,
        street: item.address,
        postCode: item.adcode,
        position: [item.location.lng, item.location.lat]
      }
    },
    // 确定选择
    setShopAddress() {
      this.dialog.visible = false
      this.addressIndex = ''
      this.searchAddressItems = []
      this.shopInfo.address = this.addressItem
    },
    // 取消
    cancel() {
      this.dialog.visible = false
      this.addressIndex = ''
      this.addressItem = ''
      this.searchAddressItems = []
    },

    // 上一步
    preStep() {
      localStorage.setItem('shop_info', JSON.stringify(this.shopInfo))
      this.$router.push({
        name: 'apply',
        query: { step: 4 }
      })
    },
    // 下一步
    nextStep() {
      if (_.isEmpty(this.shopInfo.shopImg)) {
        this.$message.error('请上传店铺招牌')
        return false
      } else {
        this.$refs.shopForm.validate(valid => {
          if (valid) {
            localStorage.setItem('shop_info', JSON.stringify(this.shopInfo))
            this.addApply()
          } else {
            return false
          }
        })
      }
    },

    // 确定申报
    addApply () {
      let data = {
        user: {
          uid: this.userInfo.id,
          mobile: this.userInfo.mobile,
          name: this.userInfo.name
        },
        contact: JSON.parse(localStorage.getItem('contact_info')),
        ownerIdCardInfo: JSON.parse(localStorage.getItem('owner_idcard_info')),
        enterpriseInfo: JSON.parse(localStorage.getItem('enterprise_info')),
        shopInfo: JSON.parse(localStorage.getItem('shop_info'))
      }
      this.$api.apply.add(data).then(res => {
        if (res.data.success) {
          localStorage.removeItem('contact_info')
          localStorage.removeItem('owner_idcard_info')
          localStorage.removeItem('enterprise_info')
          localStorage.removeItem('shop_info')
          this.$router.push({
            name: 'applyResult',
            query: {
              status: true
            }
          })
        } else {
          this.$message.error('提交失败，请检查相关内容')
        }
      })
      .catch(() => {
        this.$message.closeAll()
        this.$message.error('提交失败，请检查相关内容')
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "form";
</style>
